
























































import { mapActions, mapState } from 'vuex';
import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import { getDefaultRouteByRole } from '@/router';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import jwt_decode from 'jwt-decode';
import { AuthTokenData } from '@/api/schema';
import { required } from '@/helpers/validation';

export default {
  name: 'LoginView',
  components: {
    AuthLayout
  },
  data(): any {
    return {
      formValid: true,
      email: '',
      password: '',
      showPassword: false,
      submitProcessing: false,
      rules: {
        email: [(v) => required(v, 'E-Mail')],
        password: [
          (v) => !v?.includes(' ') || 'No blank space available',
          (v) => required(v, 'Password')
        ]
      }
    };
  },
  mounted(): void {
    this.checkAuthToken();
    this.$nextTick(() => document.body.click());
  },
  computed: {
    ...mapState('app', ['isSuperAdmin'])
  },
  methods: {
    ...mapActions('Auth', ['login', 'authorize']),
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formValid) {
          this.loginUser();
        }
      });
    },
    checkAuthToken(): void {
      const search = new URLSearchParams(window.location.search);
      const token = search.get('token');

      if (!token) return;

      const { exp }: AuthTokenData = jwt_decode(token);

      if (Date.now() < exp * 10 ** 3) {
        this.authorize(token);
        this.proceedAuthorization();
      }
    },
    proceedAuthorization(): void {
      this.$router
        .push(getDefaultRouteByRole(this.$role.name))
        .catch(errorToastMessage);
    },
    loginUser(): void {
      this.submitProcessing = true;
      const { email, password } = this;

      this.login({ email, password })
        .then(this.proceedAuthorization)
        .catch(errorToastMessage)
        .finally(() => {
          this.submitProcessing = false;
        });
    }
  }
};
